import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// init firebase
import { initializeApp } from "firebase/app";

// 正式機連線
const firebaseConfig = {
  apiKey: "AIzaSyDoZkXKK7ySz7ACwk9W-0tU7bKblN16rLg",
  authDomain: "gx-economic-adviser.firebaseapp.com",
  projectId: "gx-economic-adviser",
  storageBucket: "gx-economic-adviser.appspot.com",
  messagingSenderId: "340050850071",
  appId: "1:340050850071:web:44c3d9dd1734ea8ee68752",
  measurementId: "G-6KRFLB37J0",
};

// 測試機連線
// const firebaseConfig = {
//   apiKey: "AIzaSyDAMAPBnWeQt1DxBpA-pf2My_KQBkPawSg",
//   authDomain: "fir-58793.firebaseapp.com",
//   projectId: "fir-58793",
//   storageBucket: "fir-58793.appspot.com",
//   messagingSenderId: "673990501644",
//   appId: "1:673990501644:web:02866f6171d51f7de85529",
//   measurementId: "G-CR62Z5Y5ZD",
// };
initializeApp(firebaseConfig);

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

createApp(App).use(store).use(router).mount("#app");
