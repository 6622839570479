import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "登入頁面-GX後台" },
    component: () => import("../views/admin/Login.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: "/admin/member", //dashbard
    // beforeEnter: (to, from, next) => {
    //   const auth = getAuth();
    //   onAuthStateChanged(auth, (user) => {
    //     console.log('beforeEnter', user)
    //     if (user) {
    //       next();
    //     } else {
    //       next({ name: "Login" });
    //     }
    //   });
    // },
    component: () => import("../views/admin/App.vue"),
    children: [
      {
        path: "dashbard",
        component: () => import("../views/admin/Dashbard.vue"),
      },
      {
        path: "setting",
        name: "setting",
        meta: { title: "基本設置-GX後台" },
        component: () => import("../views/admin/Setting.vue"),
      },
      {
        path: "member",
        name: "member",
        meta: { title: "會員管理-GX後台" },
        component: () => import("../views/admin/members/List.vue"),
      },
      {
        path: "member/insert",
        name: "member-insert",
        meta: { title: "會員新增-GX後台" },
        component: () => import("../views/admin/members/Insert.vue"),
      },
      {
        path: "member/view/:memberId",
        name: "member-view",
        meta: { title: "會員瀏覽-GX後台" },
        component: () => import("../views/admin/members/View.vue"),
      },
      {
        path: "member/update/:memberId",
        name: "member-update",
        meta: { title: "會員編輯-GX後台" },
        component: () => import("../views/admin/members/Update.vue"),
      },
      {
        path: "income",
        name: "income",
        meta: { title: "經紀費管理-GX後台" },
        component: () => import("../views/admin/income/List.vue"),
      },
      {
        path: "income/insert",
        name: "income-insert",
        meta: { title: "經紀費新增-GX後台" },
        component: () => import("../views/admin/income/Insert.vue"),
      },
      {
        path: "income/update/:docId?/:memberId?",
        name: "income-update",
        meta: { title: "經紀費編輯-GX後台" },
        component: () => import("../views/admin/income/Update.vue"),
      },
      {
        path: "income/brokeragefees_audited",
        name: "brokeragefees_audited",
        meta: { title: "經紀費報表-GX後台" },
        component: () => import("../views/admin/income/Audited.vue"),
      },
      {
        path: "transcation",
        name: "transcation",
        meta: { title: "G幣交易紀錄-GX後台" },
        component: () => import("../views/admin/transcation/List.vue"),
      },
      {
        path: "notification",
        name: "notification",
        meta: { title: "推播功能" },
        component: () => import("../views/admin/notification/List.vue"),
      },
      {
        path: "notification/announcement",
        name: "notification-announcement",
        meta: { title: "公告功能" },
        component: () => import("../views/admin/notification/Announcement.vue"),
      },
      {
        path: "notification/calendar",
        name: "notification-calendar",
        meta: { title: "推播及公告日程表" },
        component: () => import("../views/admin/notification/Calendar.vue"),
      },
      {
        path: "looker_studio/introducer_profit_report",
        name: "looker_studio-introducer_profit_report",
        meta: { title: "介紹人分潤報表" },
        component: () =>
          import("../views/admin/lookerStudio/IntroducerProfitReport.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  const rules = new RegExp("^/admin/");

  if (rules.test(to.path)) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const authRes = authority(user.email, to.name);
        if (authRes) {
          next();
        } else {
          alert("無權限使用此功能");
          return false;
        }
      } else {
        window.location = "/";
      }
    });
  } else {
    next();
  }
});

function authority(user, to) {
  // auth allow優先度大於role，假如有設定auth allow則不套用role的角色群組權限
  const adminUsers = {
    "yoghurt@winho.com.tw": { role: "sys" },
    "kenluo@gmail.com": { role: "sys" },
    "hank@gmail.com": { role: "sys" },
    "sun@mywonder.com": { role: "sys" },
    // "greentaco1113@gx888.com.tw": { role: "admin" },
    "mimi20000112@hotmail.com": { role: "admin" },
    "jonathan@gx888.com.tw": { role: "admin" },
    "yumei.lin@homies.com.tw": { role: "for_yumei" },
    // "jie@gx888.com.tw": { role: "for_jie" },
    "jie.hung@gx888.com.tw": { role: "for_jie" },
    "jean@gx888.com.tw": { role: "memberAll_transcation" },
    "dong@gx888.com.tw": { role: "memberAll_transcation_update" },
    "william@gx888.com.tw": {
      auth: { allow: ["member", "member-update", "transcation"] },
    },
    "zzz0402@gx888.com.tw": { role: "memberAll_onlyview" },
    "st1005614@gx888.com.tw": { role: "memberAll_onlyview" },
  };

  // 角色群組權限
  const rolesAuth = {
    sys: { allAllow: true },
    admin: { allAllow: true },
    member_manager: {
      allow: ["member", "member-update"],
    },
    memberAll_onlyview: {
      allow: ["member", "member-view"],
    },
    memberAll_transcation: {
      allow: ["member", "member-view", "transcation"],
    },
    memberAll_transcation_update: {
      //原本是給jie@gx888.com.tw
      allow: [
        "member",
        "member-insert",
        "member-update",
        "transcation",
        "income",
      ],
    },
    for_jie: {
      allow: [
        "member",
        "member-insert",
        "member-update",
        "income",
        "income-insert",
        "brokeragefees_audited",
        "looker_studio-introducer_profit_report",
        "transcation",
      ],
    },
    for_yumei: {
      allow: [
        "member",
        "member-update",
        "income",
        "brokeragefees_audited",
        "looker_studio-introducer_profit_report",
        "transcation",
        "income-insert",
      ],
    },
  };

  const role = adminUsers[user].role;
  let auth;

  if (adminUsers[user].auth != undefined) {
    auth = adminUsers[user].auth;
    // console.log('yes1')
  } else {
    auth = rolesAuth[role];
    // console.log('yes2')
  }

  // console.log("auth", auth)

  // 將role存到vuex的setAdminUserRole內
  store.commit("setAdminUserRole", role);

  if (auth.allAllow && auth.allAllow === true) {
    return true;
  }

  if (auth.allow && auth.allow.includes(to)) {
    return true;
  } else {
    return false;
  }
}

export default router;
