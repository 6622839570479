import { createStore } from "vuex";

export default createStore({
  state: {
    adminUserRole: null,
  },
  mutations: {
    setAdminUserRole(state, role) {
      state.adminUserRole = role;
      // alert(role)
    },
  },
  actions: {},
  modules: {},
});
